div.message {
    color: #FF5252;
    background-color: #FFEBEA;
    margin: 30px;
    padding: 30px;
    text-align:left;
    font-weight: bold;
    font-size: 20px;
}

div.message-mobile {
    color: #FF5252;
    background-color: #FFEBEA;
    padding: 30px;
    text-align:left;
    font-weight: bold;
    font-size: 20px;
}