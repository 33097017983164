body {
  background-color: #808080;
}

div#formArea {
  width: 600px;
  padding: 40px 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  background-color: #15202B;
  text-align: center;
  color: white;
}

div#formArea-Mobile {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  background-color: #15202B;
  text-align: center;
  color: white;
}

img#title_logo {
  max-width : 100px;
}

h1.title#PC {
  padding: 0px 0px 0px 0px; 
  background-image: url("logo256.png");
  background-size: 60px 60px; /* 画像サイズ */
  background-repeat: no-repeat; /* 繰り返し表示オフ */
  padding: 0px 0px 0px 20px; 
  margin-left: 100px;
  margin-right: 100px;
  line-height: 60px;
}

h1.title#Mobile {
  background-image: url("logo256.png");
  background-size: 45px 45px; /* 画像サイズ */
  background-repeat: no-repeat; /* 繰り返し表示オフ */
  padding: 0px 0px 0px 20px; 
  margin-left: 80px;
  margin-right: 80px;
}
form h1,form p, form {
  color: white;
  font-weight: 500;
  margin: 30px;
}

/*label#PC {
  color: white;
  font-weight: 500;
  display: flex;
}*/

label#Mobile {
  color: white;
  font-weight: 500;
  display: flex;
}

form p {
  font-size: 20px;
}

.PasswordReveal {
  left: auto;
  position: absolute;
  right: 10px;
  top: 10px;
}

form input.pw1,form input.pw2 {
  height: 30px;
  width: 300px;
  font-size: 20px;
}

form input[type = "submit"]{
  border: 0;
  background: none;
  display: block;
  margin: 20px auto;
  border: 2px solid #f0f8ff;
  padding: 10px 10px;
  width: 200px;
  outline: none;
  color: white;
  border-radius: 25px;
  transition: 0.25s;
  text-align: center;
  cursor: pointer;
  font-size: 20px;
  margin-top: 50px;
}

div#password_form_child_PC {
  margin-top: 30px;
  display: flex;
  line-height: 33px;
}

div#form {
  display: block;
  white-space: nowrap;
}

div#form_container_PC {
  margin-left: 0px;
}

div#form_container_Mobile {
  margin-left: 40px;
}

.passwordReveal {
  color: white;
  margin-left: 10px;
  margin-right: 30px;
}

form input[type = "submit"]:hover {
  background-color: #f18301;
  border: 2px solid #f18301;
  font-weight: 200;
  white-space: nowrap;
}
