div#succeeded {
    background-color: #EBF5EB;
    text-align: center;
    color: #009688;
    padding: 50px;
    margin: 30px;
    padding: 30px;
    text-align:left;
    font-weight: bold;
    font-size: 20px;
    min-width: 350px;
}

div#failed {
    background-color: #FFEBEA;
    text-align: center;
    color: #FF5252;
    padding: 50px;
    margin: 30px;
    padding: 30px;
    text-align:left;
    font-weight: bold;
    font-size: 20px;
    min-width: 350px;
}
